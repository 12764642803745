@import "mixins"
.draggable
  z-index: 900
  position: absolute
  background: var(--body_bg)
  border-radius: 4px
  overflow: hidden
  box-shadow: 0 0 16px rgba(#000, 0.6)
  :global(.widgets)
    min-height: 142px !important
    position: relative
    :global(.loader)
      min-height: unset !important
      max-height: unset !important

.header
  height: 34px
  background: var(--betslip_headerBg)
  border-bottom: 1px solid var(--betslip_borderLight)
  box-shadow: inset 0 -1px #000
  width: 100%
  cursor: move
  padding: 0 24px 0 10px
  @include flex
  position: relative
  :global(.svg-sport-icon)
    margin-right: 8px

.close
  @include flex
  justify-content: center
  width: 18px
  height: 100%
  position: absolute
  top: 0
  right: 5px

  &:before
    @include closeIcon

  &:hover
    @include pointer

    &:before
      opacity: 1
