@import "mixins"
.rightSide
  margin-top: calc((var(--base_mainTabHeight) + var(--markets_gap) + var(--middleArea_headerHeight)) * -1)
  flex: 1
  position: relative

.rightSideContent
  padding-top: calc(var(--base_mainTabHeight) + var(--markets_gap) + var(--middleArea_headerHeight))
  padding-right: var(--scroll_width)!important

.betSlipContentHeader
  padding: 0 10px
  height: 32px
  @include flex
  justify-content: space-between
  font-size: var(--base_smallFontSize)
  color: rgb(var(--primaryColor))
  background: var(--betslip_rowBg)

.placeBetContainer
  overflow: hidden
  @include flex
  flex-wrap: wrap
  padding: var(--betslip_placeBetContainerPadding)
  border-top: 1px solid var(--betslip_placeBetContainerBorderLight)
  border-bottom: 1px solid var(--betslip_borderDark)
  box-shadow: 0 1px var(--betslip_placeBetContainerBorderLight)
  background: var(--betslip_placeBetContainerBg)
  &:after
    content: var(--base_beforeContent)
    border-top: 1px solid var(--betslip_borderLight)
    box-shadow: 0 -1px var(--betslip_borderDark)
    margin: 6px 0 -5px
    width: 100%

  > span:first-child
    color: rgba(var(--secondaryColor), 0.8)
    margin-right: auto
    margin-left: 8px
    &:hover
      @include pointer
      color: rgba(var(--secondaryColor), 0.9)

.removeAllButton
  text-shadow: 0 1px rgba(0, 0, 0, 0.8)
  line-height: 23px
  float: left
  @include pointer

.betItemsContainer
  position: relative

.noBetItem
  @include flex
  flex-direction: column
  justify-content: center
  color: var(--betslip_noItemColor)
  height: 150px
  font-size: 12px
  padding-bottom: 12px
  border-bottom: 1px solid var(--betslip_borderDark)

  &:before
    content: var(--noDataContent)
    margin-bottom: 8px
    width: 86px
    height: 100px
    background: var(--icon-no-bets) no-repeat

.acceptedBet
  font-size: 12px
  color: var(--betslip_acceptedBetColor)
  @include flex
  flex-direction: column
  justify-content: center
  position: absolute
  background: var(--betslip_acceptedBetBg)
  border: 1px solid var(--betslip_acceptedBetBorder)
  border-radius: 6px
  padding: 8px
  min-width: 90px
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  text-transform: uppercase
  :global(.imageNext)
    margin-bottom: 10px
