@import "mixins"
.acceptBetPlacement
  border-bottom: 1px solid var(--betslip_borderLight)
  box-shadow: inset 0 -1px var(--betslip_borderDark)
  border-top: 1px solid var(--betslip_borderLight)
  padding: 10px 8px 12px

.placementText
  color: rgb(var(--primaryColor))
  font-size: var(--base_smallFontSize)
  text-align: center
  padding: 0 6px
  line-height: 16px
  margin-bottom: 14px

.buttonsContainer
  @include flex
  padding-left: 8px
  color: rgb(var(--primaryColor))
  font-size: var(--base_smallFontSize)

  > span
    flex: 1 auto
    @include flex
    min-width: 28%
    @include ellipsis

    i
      border: 1px solid #121212
      width: 12px
      height: 12px
      @include flex
      justify-content: center
      border-radius: 100%
      background: #9097a2
      box-shadow: 0 1px rgba(var(--secondaryColor), 0.1)
      margin-right: 8px

    &.checked i:before
      content: ""
      width: 6px
      height: 6px
      background: #3370dc
      border-radius: 100%

    &:hover:not(.checked)
      color: rgb(var(--secondaryColor))
      @include pointer
