@import "mixins"
.selectType
  @include flex
  height: 34px
  border-top: 1px solid var(--betslip_borderDark)
  box-shadow: inset 0 1px var(--betslip_borderLight)
  border-bottom: 1px solid var(--betslip_borderDark)
  padding: 0 8px
  font-size: var(--base_smallFontSize)
  margin-top: 1px

.dropdown
  margin-left: 10px
  > div:first-of-type
    border: 1px solid #2c3138
    background: rgb(var(--secondaryColor))
    height: 24px
    min-width: 56px
    padding: 0 6px
    font-size: var(--base_smallFontSize)

.totalStake
  padding: 0 6px
  border-top: 1px solid var(--betslip_borderLight)
  border-bottom: 1px solid var(--betslip_borderLight)
  background: var(--betslip_rowBg)

.totalStakeItem
  @include flex
  min-height: 34px
  .totalOddsWarning
    flex-direction: unset !important
    justify-content: flex-start !important
    align-items: center
  &:not(:last-child)
    border-bottom: 1px solid var(--betslip_borderLight)

  &:has(p) > span:first-of-type
    margin-top: 18px

  > span
    padding: 6px 10px 6px 2px
    line-height: var(--betslip_stakeItemHeight)
    font-size: var(--base_smallFontSize)

    &:first-child
      position: relative
      min-width: 90px
      max-width: 90px
      padding: 4px 6px 4px 2px

    &:last-child
      display: flex
      flex-direction: column
      justify-content: center
      padding: 0 0 0 8px
      line-height: 12px
      flex: 1
      white-space: nowrap

      strong
        color: rgb(var(--secondaryColor))
        @include flex
        :global(.svg-icon)
          margin-left: 4px

      > span
        display: block
        line-height: 12px
        margin-top: 1px

      > input
        margin: 0

    &:not(:last-child):after
      content: ""
      position: absolute
      right: 0
      width: 1px
      height: 80%
      top: 10%
      background: var(--betslip_borderLight)

.notSingleTotalStakeItem
  flex-wrap: wrap
  > span:last-child
    padding-top: 4px
    padding-bottom: 4px
    > div
      margin: 0

.totalOddsWarningIcon
  margin-left: 8px

.betProcessing
  pointer-events: none
  opacity: 0.3
