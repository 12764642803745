@import "mixins"
.betView
  width: 330px
  min-width: 330px
  position: relative
  z-index: 10
  height: 100%
  overflow: hidden
  box-shadow: -1px 0 var(--scroll_border)
  background: var(--betslip_bg)

  & :global
    .main-tabs
      width: calc(100% - var(--scroll_width))
      border-color: var(--sidebar_tabSportBtnActiveHeaderBorderColor)
      padding-top: var(--markets_gap)

      .main-tabs-tab
        background: transparent
        text-transform: var(--base_textTransform)
        &:disabled
          opacity: .2
        &.active
          background: var(--sidebar_tabSportBtnActive)
          color: var(--button_color)
        &:not(.active):not(:first-of-type)
          border-left: 0

@media screen and (max-width: 1385px)
  .betView
    width: 300px
    min-width: 300px
