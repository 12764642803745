@import "mixins"
.tournamentItemsList
  overflow: hidden

.tournamentItem
  background: var(--sidebar_tournamentBg)
  height: var(--sidebar_tournamentHeight)
  @include flex
  padding: 0 8px
  border-bottom: var(--sidebar_tournamentBorder)
  &:last-of-type
    border-bottom: 1px solid var(--sidebar_sportItemBorderBottom)
  > span
    @include ellipsis
    line-height: 15px
    font-size: var(--sidebar_sportItemFontSize)

  &.checked > span
    color: rgba(var(--thirdaryColor))

  &:hover:not(.checked)
    @include pointer
    > span
      color: rgb(var(--secondaryColor))

  .checkbox
    margin: -1px 10px 0 2px
    @include flex

    &::after
      content: var(--base_beforeContent)
      padding-right: 12px
      width: 1px
      height: 12px
      background-image: linear-gradient(var(--base_verticalSeparator) 33%,rgba(255, 255, 255, 0) 0%)
      background-position: right
      background-size: 1px 2px
      background-repeat: repeat-y

    > i
      border: var(--sidebar_sportIconBorder)
      box-shadow: var(--sidebar_sportIconShadow)
      background: var(--sidebar_tournamentCheckboxBg)
      border-radius: var(--sidebar_tournamentCheckboxRadius)
    &:global(.checked) input[type="checkbox"] + i
      background: rgb(var(--secondaryColor))
