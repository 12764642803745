@import "mixins"
.content
  display: flex
  width: 100%
  height: calc(100% - var(--header_height))
  overflow: hidden
  position: relative

.middleArea
  z-index: 1
  width: 100%
  height: 100%
  position: relative
  // for home page banner
  min-width: 0
