@import "mixins"
.active
  color: var(--footer_linkActive) !important

.socialLink
  background: var(--footer_socialLinkBg)
  width: 30px
  height: 30px
  border-radius: 30px
  @include flex
  justify-content: center

  > span
    @include flex
    opacity: 0.7

  &:hover
    background: var(--footer_socialLinkBgHover)

    > span
      opacity: 1
    :global(.svg-icon)
      path
        fill: rgb(var(--secondaryColor))

  &:active
    opacity: 0.7

  &:not(:last-of-type)
    margin-right: 10px

.licenseLogo > *
  margin: 6px 10px -4px

.footerText
  padding: 10px 28px 8px
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)
  line-height: 16px
  opacity: 0.8
  display: block!important
  color: rgb(var(--base_textColor))

@media screen and (max-width: 1700px)
  .footerLinks nav
    min-width: calc(100% / 3)

  .footerCurrencyLogos > div
    min-width: 50%

.footerCurrencyLogos
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow), inset 0 1px var(--footer_shadow)
  border-top: 1px solid var(--footer_border)
  justify-content: space-around !important
  flex-wrap: wrap
  margin-top: 10px
  min-height: 50px
  padding: 10px

  > span
    margin: 0 10px !important

  @media screen and (max-width: 1760px)
    padding: 10px 20px
    justify-content: space-around
    > span
      margin: 0 14px !important
      text-align: center

.beGambleAware
  padding: 10px
  border-bottom: 1px solid var(--footer_border)
  box-shadow: 0 1px var(--footer_shadow)
  @include flex
  flex-direction: column
  justify-content: center
  .age
    margin-top: 10px
    border: 2px solid #CCEF57
    background-color: transparent
    color: rgb(var(--secondaryColor))

.version
  padding-bottom: 10px
  text-align: center
  font-size: 11px
  color: var(--footer_versionColor)
