@import "mixins"
.container
  background: var(--sidebar_liveTournamentContainerBg)
  overflow: hidden
  border-bottom: 1px solid var(--sidebar_liveSportItemBorderBottom)

  .tournament div > a:last-of-type
    border-bottom-color: var(--sidebar_liveSportItemBorderBottom)

  .tournament:last-of-type div > a:last-of-type
    border-bottom: var(--sidebar_sportIconBorder)

.tournament
  margin: var(--sidebar_liveTournamentContainerMargin)
  border: var(--sidebar_sportIconBorder)
  border-right: 0
  border-bottom: 0
  overflow: hidden

.header
  background: var(--sidebar_liveTournamentHeaderBg)
  @include flex
  font-size: var(--sidebar_liveTournamentHeaderFontSize)
  text-transform: var(--base_textTransform)
  height: var(--sidebar_liveTournamentHeaderHeight)
  border-top: var(--sidebar_liveTournamentHeaderBorderTop)
  border-bottom: 1px solid var(--sidebar_liveTournamentHeaderBorderBottom)
  padding: 0 6px
  position: relative
  z-index: 1
  :global(.svg-icon)
    margin-right: 6px
    opacity: 0.8
    svg
      width: 14px
      height: 12px

  :global(.svg-sport-icon)
    margin-right: 8px
    min-width: var(--sidebar_liveSportIconSize)
    height: var(--sidebar_liveSportIconSize)
    opacity: var(--sidebar_sportIconOpacity)
    @include flex
    > span
      @include flex
    svg
      width: var(--sidebar_liveSportIconSize)
      height: var(--sidebar_liveSportIconSize)

  &.opened
    background: var(--sidebar_liveTournamentHeaderBgOpened)
    color: rgb(var(--secondaryColor))
    :global(.svg-sport-icon)
      opacity: 1

  &:hover:not(.opened)
    background: var(--sidebar_liveTournamentHeaderBgOpened)
    color: rgb(var(--secondaryColor))
    @include pointer
    :global(.svg-sport-icon)
      opacity: 1

  &.topTournamentHeader
    @include pointer

.tournamentName
  margin-right: auto
  line-height: calc(var(--sidebar_liveTournamentHeaderFontSize) + 2px)
  @include ellipsis
  color: var(--prematch_tournamentHeaderColor)
