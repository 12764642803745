@import "mixins"
.infoTooltip
  position: absolute
  z-index: 5
  padding: 8px
  max-width: 203px
  line-height: 13px
  background: #41464f
  color: rgb(var(--secondaryColor))
  border-top: 1px solid rgb(var(--particularColor2))
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  font-size: 11px
  font-weight: normal
  margin-top: 2px
  box-shadow: 0 1px 3px rgba(65, 82, 113, 0.6)

  &:before
    content: ""
    width: 0
    height: 0
    border-style: solid
    border-width: 0 3px 4px 3px
    border-color: transparent transparent rgb(var(--particularColor2)) transparent
    position: absolute
    top: -5px

  &.top
    max-width: 100%
    border-top: 0
    border-bottom: 1px solid rgb(var(--particularColor2))
    margin-top: -36px
    top: 0
    &:before
      border-width: 4px 3px 0 3px
      border-color: rgb(var(--particularColor2)) transparent transparent transparent
      top: unset
      bottom: -5px