@import "mixins"
.sportsNav
  border-top: 1px solid var(--sidebar_borderTop)
  padding-bottom: 30px

  & :global
    .main-tabs
      font-size: 11px

  > ul > li:first-of-type > div
    border-top: 0

.groupTitle
  padding: 0 8px
  margin: var(--sidebar_groupTitleMargin)
  background: var(--sidebar_groupTitleBg)
  border-radius: var(--sidebar_groupTitleRadius)
  border-bottom: 1px solid var(--sidebar_groupTitleBorderBottom)
  @include flex
  font-weight: var(--base_fontWeight)
  font-size: var(--base_fontSize)
  color: var(--sidebar_groupTitleColor)
  justify-content: space-between
  min-height: var(--sidebar_sportItemHeight)
  text-transform: uppercase

  //> span {
  //    font-size: 11px;
  //    font-weight: normal;
  //    text-transform: capitalize;
  //}

.title
  composes: title from "./top/prematch-top-leagues.module.sass"

.prematchFavoritesButton, .upcomingBtn, .topMatchesBtn
  @include flex
  justify-content: center
  background: var(--sidebar_favoritesBg)
  margin: var(--sidebar_favoritesMaqrgin)
  border: 1px solid var(--base_borderColor)
  border-radius: var(--base_borderRadius)
  box-shadow: var(--sidebar_sportIconShadow)
  position: relative
  color: rgba(var(--secondaryColor), 0.8)
  height: var(--sidebar_favoritesHeight)
  @include bold
  font-size: var(--sidebar_sportItemFontSize)
  line-height: 13px
  text-transform: uppercase
  &:hover
    color: rgb(var(--secondaryColor))

  &.active .arrow
    visibility: visible

.prematchFavoritesButton
  .favoriteStar
    position: absolute
    left: 6px
    top: 50%
    transform: translateY(-50%)
    mask-image: var(--icon-favorite)
    min-width: 16px
    height: 16px
    background: var(--star_favoriteActiveFill)

  &.active
    background: var(--sidebar_favoritesBgActive)
    color: var(--sidebar_favoritesActiveColor)

.arrow
  position: absolute
  right: 4px
  top: 50%
  transform: translateY(-50%)
  opacity: 0.8
  @include flex
  visibility: hidden
  :global(.imageNext)
    transform-origin: 48%
    transform: rotate(-90deg)
    display: inline-flex
    &:first-of-type
      margin-right: -6px
      margin-left: 5px

.topMatchesBtn
  :global(.svg-icon)
    position: absolute
    left: 6px
    top: 50%
    transform: translateY(-50%)
    svg
      width: auto
      height: 16px
      path
        fill: #e8cf74!important

  &.active
    background: var(--sidebar_topMatchesBtnBg)
    color: rgb(var(--secondaryColor))
    :global(.svg-icon)
      path
        fill: rgb(var(--secondaryColor))!important

.upcomingBtn
  background: var(--sidebar_favoritesBg)

  :global(.svg-icon)
    position: absolute
    left: 6px
    top: 50%
    transform: translateY(-50%)
    svg
      width: auto
      height: 16px
      path
        fill: #e8cf74!important

  &.active
    background: var(--sidebar_upcomingBtnBg)
    color: rgb(var(--secondaryColor))
    :global(.svg-icon)
      path
        fill: rgb(var(--secondaryColor))!important
